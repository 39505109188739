import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NewMovementTypeForm = _resolveComponent("NewMovementTypeForm")!
  const _component_DynamicContentModal = _resolveComponent("DynamicContentModal")!

  return (_openBlock(), _createElementBlock("section", null, [
    _createVNode(_component_DynamicContentModal, { targetId: 'new-movement-type-form' }, {
      default: _withCtx(() => [
        _createVNode(_component_NewMovementTypeForm, {
          formType: _ctx.formType,
          onCloseContent: _ctx.closeModal
        }, null, 8, ["formType", "onCloseContent"])
      ]),
      _: 1
    })
  ]))
}