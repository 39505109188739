import Modules from "@/store/enums/Modules";
import store from "@/store";
import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { Identifiable } from "@/models/general/Identifiable";
import { realoadTable } from '@/store/functions/GenericTable';
import { MutationsForm } from "@/store/commons/MutationsForm"
import { ActionsForm } from "@/store/commons/ActionsForm";
import NewMovementType from "@/models/movementTypes/NewMovement";
import NewMovementTypeState from "../../models/NewMovementTypeState";
import MovementTypeService from "@/core/services/movementTypes/MovementTypeService";


@Module({dynamic: true, store, namespaced: true, name: Modules.NewMovementTypeModule})
class NewMovementTypeModule extends VuexModule implements NewMovementTypeState {
    idSelected = '';
    /**
     * Los valores iniciales son los valores del formulario, se usan para las ediciones
     */
    initialValues = formDefaultValues
    loading = false;
    

    get getValuesForm(): NewMovementType {
        return this.initialValues;
    }
    /**
     * Indica si existe un identificador de un cliente a modificar
     */
    get IsUpdateForm() : boolean {
        return this.idSelected != '';
    }
    
    @Mutation
    [MutationsForm.RESET_VALUES_FORM](){
        this.initialValues = formDefaultValues;
    }
    @Mutation
    [MutationsForm.RESET_ID_SELECT](){
        this.idSelected = '';
    }

    @Mutation
    [MutationsForm.SET_DATA_FORM](values: Identifiable<string, NewMovementType>){
        if(values && (values.id ?? '') != ''){
            this.initialValues = values as NewMovementType;
            this.idSelected = values.id;
        }
    }

    @Mutation
    [MutationsForm.SET_LOADINGS](formLoading: boolean){
        this.loading = formLoading;
    }

    @Action
    async [ActionsForm.SAVE_FORM](form: NewMovementType){
        this.context.commit(MutationsForm.SET_LOADINGS, true);
        return service.save(form)
            .then((resp) => {
                console.log(resp);
                if(resp.data){
                    realoadTable(this.context, Modules.TableMovementTypeModule)
                    this.context.commit(MutationsForm.RESET_VALUES_FORM)
                } 
                return resp.data;
            })
            .finally(() => {
                this.context.commit(MutationsForm.SET_LOADINGS, false)
            })
    }
    @Action
    async [ActionsForm.UPDATE_FORM](newData: NewMovementType){
        this.context.commit(MutationsForm.SET_LOADINGS, true);
        return service.update(this.idSelected, newData)
            .then((resp) => {
                if(resp.data){
                    realoadTable(this.context, Modules.TableMovementTypeModule)
                    this.context.commit(MutationsForm.RESET_VALUES_FORM)
                } 
                return resp.data;
            })
            .finally( () => {
                this.context.commit(MutationsForm.SET_LOADINGS, false)
            });
    }
    @Action({ commit: MutationsForm.SET_DATA_FORM })
    async [ActionsForm.SEARCH_DATA_FORM](id: string){
        this.context.commit(MutationsForm.SET_LOADINGS, true);
        return (await service.getInfo(id)
            .finally( () => this.context.commit(MutationsForm.SET_LOADINGS, false))).data ?? formDefaultValues
    }
}
const service =  new MovementTypeService();

/**
 * Valores por defecto del formulario
 */
 const formDefaultValues = {
     eMovementType: null
    , name: '' 
 } as NewMovementType

export default getModule(NewMovementTypeModule);

