import MovementTypeRow from "@/models/movementTypes/MovementTypeRow";
import CrudService from "../general/interfaces/CrudService";
import GeneralService from "../interfaces/GeneralService";
import TableMovementTypeService from "./interfaces/TableMovementTypeService";
import MessageService from "../interfaces/MessageService";
import SwalMessageService from "../SwalMessageService";
import PaginationRequest from "@/models/general/PaginationRequest";
import PaginationResponse from "@/models/general/PaginationResponse";
import GeneralFiltersTable from "@/models/general/Paginations/Filters/GeneralFiltersTable";
import { AxiosResponse } from "axios";
import CatalogService from "../general/CatalogService";
import NewMovementType from "@/models/movementTypes/NewMovement";


export default class MovementTypeService  extends CatalogService<MovementTypeRow, NewMovementType>{
    constructor(){
        super("/MovementTypes", new SwalMessageService() );
    }
}

/*export default class MovementTypeService implements GeneralService, TableMovementTypeService
    , CrudService<MovementTypeRow, MovementTypeRow> {
       
        
        apiController = "MovementTypes"
        messageService: MessageService = new SwalMessageService(); 
    
        searchTableMovementTypes(pagination: PaginationRequest, filters: GeneralFiltersTable): Promise<AxiosResponse<PaginationResponse<MovementTypeRow>>> {
            return ApiService.get<PaginationResponse<TransferRow>>(this.apiController, 
            `GetPagedList?`//puede cambiar esta ruta
            + `CurrentPage=${pagination.currentPage}`
            + `&PageSize=${pagination.pageSize}`
            + (filters.folio && filters.folio != '' ? `&Criteria.Folio=${filters.folio}` : '')
            + (customerIdsFilters && customerIdsFilters != '' ? customerIdsFilters : '')
            + (productIdsFilters && productIdsFilters != '' ? productIdsFilters : '')
            + (filters.startDate && filters.startDate != '' ? `&Criteria.InitialDate=${filters.startDate}` : '')
            + (filters.endDate && filters.endDate != '' ? `&Criteria.FinalDate=${filters.endDate}` : '')
        ).then( resp => {
            return resp;
        }).catch(error => {
            getMessageError(error, this.messageService);
            return { data: null} as AxiosResponse;
        });
        }
    
         getInfo(id: string): Promise<AxiosResponse<MovementTypeRow>> {
            throw new Error("Method not implemented.");
        }
        save(form: MovementTypeRow): Promise<AxiosResponse<boolean>> {
            throw new Error("Method not implemented.");
        }
        update(id: string, newInfo: MovementTypeRow): Promise<AxiosResponse<boolean>> {
            throw new Error("Method not implemented.");
        }
    }*/