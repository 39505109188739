export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "es": {
        "LABELS": {
          "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
          "MOVEMENT_TYPE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de Movimiento"])}
        },
        "PLACE_HOLDERS": {
          "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
          "MOVEMENT_TYPE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecciona el tipo de movimiento"])}
        },
        "BUTTONS": {
          "CLEAN_FORM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limpiar"])},
          "SAVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardar"])},
          "UPDATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar"])},
          "LOAD_SAVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardando..."])},
          "LOAD_UPDATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualizando..."])}
        }
      }
    }
  })
}
