import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, mergeProps as _mergeProps, withCtx as _withCtx, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_form_item = _resolveComponent("el-form-item")!

  return (_openBlock(), _createElementBlock("section", null, [
    _createVNode(_component_el_form_item, { error: _ctx.errorMessage }, {
      default: _withCtx(() => [
        _createVNode(_component_el_select, _mergeProps({
          disabled: _ctx.disabled,
          "reserve-keyword": "",
          placeholder: _ctx.placeholder,
          onChange: _cache[0] || (_cache[0] = (idSelected) => _ctx.$emit('change', idSelected)),
          modelValue: _ctx.value,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.value) = $event))
        }, _ctx.$attrs, { clearable: "" }), {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (item) => {
              return (_openBlock(), _createBlock(_component_el_option, {
                key: item.id,
                label: item.name,
                value: item.id
              }, null, 8, ["label", "value"]))
            }), 128))
          ]),
          _: 1
        }, 16, ["disabled", "placeholder", "modelValue"])
      ]),
      _: 1
    }, 8, ["error"])
  ]))
}