import OptionSelect from "@/models/shared/OptionSelect"
import { translate } from "../plugins/i18n"

enum MovementType {
    Inputs =  0
    , Outputs
    , Returns
    , Sales
    , Shopping
    , MaintenanceServices
    , Transfer
    , PurchaseOrder
    , Requisition
}

/**
 * Obtiene los valores del enumerador
 */
const possibleValues = Object.values(MovementType)
    .filter((v) => !isNaN(Number(v)))

function getOptions(): OptionSelect[] {
    return [
        {
            id: MovementType.Inputs
            , name: translate("ENUMS.MOVEMENT_TYPES.Inputs")
        },
        {
            id: MovementType.Outputs
            , name: translate("ENUMS.MOVEMENT_TYPES.Outputs")
        },
        {
            id: MovementType.Returns
            , name: translate("ENUMS.MOVEMENT_TYPES.Returns")
        },
        {
            id: MovementType.Sales
            , name: translate("ENUMS.MOVEMENT_TYPES.Sales")
        },
        {
            id: MovementType.MaintenanceServices
            , name: translate("ENUMS.MOVEMENT_TYPES.MaintenanceServices")
        },
        {
            id: MovementType.Transfer
            , name: translate("ENUMS.MOVEMENT_TYPES.Transfer")
        },
        {
            id: MovementType.PurchaseOrder
            , name: translate("ENUMS.MOVEMENT_TYPES.PurchaseOrder")
        },
        {
            id: MovementType.Requisition
            , name: translate("ENUMS.MOVEMENT_TYPES.Requisition")
        },
    ]
}
export { possibleValues, getOptions , MovementType}