
import OptionSelect from '@/models/shared/OptionSelect'
import { useField } from 'vee-validate';
import  { defineComponent, PropType } from 'vue'

export default defineComponent({
    props:{
        options: {
            type: Array as PropType<Array<OptionSelect>>
            , required: true
        },
        name: {
            type: String
            , required: true
        },
        placeholder: {
            type: String
            , default: 'Seleccionar'
        },
        disabled: {
            type: Boolean
            , default: false
        }
    },
    data(){
        const { value, errorMessage } = useField(this.name);
        return {
            value
            , errorMessage
        }
    },
})
