import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  hidden: "",
  ref: "openModal",
  class: "btn btn-primary er fs-6",
  "data-bs-toggle": "modal",
  "data-bs-target": "#new-movement-type-form"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CrudActionsV2 = _resolveComponent("CrudActionsV2")!
  const _component_Datarow = _resolveComponent("Datarow")!
  const _component_Datatable = _resolveComponent("Datatable")!
  const _component_ModuleMaster = _resolveComponent("ModuleMaster")!
  const _component_ModalNewMovementType = _resolveComponent("ModalNewMovementType")!

  return (_openBlock(), _createElementBlock("section", null, [
    _createVNode(_component_ModuleMaster, {
      requireSearchField: true,
      requireAddButton: true,
      requirePaginator: true,
      useFilters: false,
      labels: _ctx.labels,
      numItemsPaginator: _ctx.totalRecords,
      selectedPage: _ctx.selectedPage,
      itemsPerPage: _ctx.itemsPerPage,
      fnCallbackChangePage: _ctx.handleChangePage,
      fnCallbackCrudAction: _ctx.handleCrudAction,
      fnCallbackSearchItems: _ctx.searchItems
    }, {
      default: _withCtx(() => [
        _createVNode(_component_Datatable, {
          headers: _ctx.headers,
          requireActions: true,
          requireStatusCol: false
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.renderRows, (row) => {
              return (_openBlock(), _createBlock(_component_Datarow, {
                key: row.ID,
                dataRow: row,
                showCrudActions: true,
                requireStatusCol: false,
                statusPropName: 'isActive'
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_CrudActionsV2, {
                    onActionCrud: ($event: any) => (_ctx.handleCrudAction(...arguments, row.ID))
                  }, null, 8, ["onActionCrud"])
                ]),
                _: 2
              }, 1032, ["dataRow"]))
            }), 128))
          ]),
          _: 1
        }, 8, ["headers"])
      ]),
      _: 1
    }, 8, ["labels", "numItemsPaginator", "selectedPage", "itemsPerPage", "fnCallbackChangePage", "fnCallbackCrudAction", "fnCallbackSearchItems"]),
    _createElementVNode("button", _hoisted_1, null, 512),
    _createVNode(_component_ModalNewMovementType, { formType: _ctx.modeForm }, null, 8, ["formType"])
  ]))
}